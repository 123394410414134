// types
import { ConfigProps } from 'types/config';

export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const FIREBASE_API = {
    apiKey: "AIzaSyCBw71LCryawko0zyM699QKaitgdjkXtiY",
    authDomain: "zimi-production.firebaseapp.com",
    databaseURL: "https://zimi-production.firebaseio.com",
    projectId: "zimi-production",
    storageBucket: "zimi-production.appspot.com",
    messagingSenderId: "139298138144",
    appId: "1:139298138144:web:7e0b51495a174c3b18b445",
    measurementId: "G-Y30B33ZZE5"
};

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard';

const config: ConfigProps = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;

const zimicoreConfig = {
    baseUrl: 'zimicore.zimi.life'
}
const zimiserviceConfig = {
    baseUrl: 'zimiservice.zimi.life'
}

const cpActionsConfig = {
    // baseUrl: 'localhost:3001'
    baseUrl: 'voiceassistant.zimi.life'
}

const deviceTypes = {
    "dimmer": "1gdimmer",
    "gpo": "2ggpo",
    "clayton": "1g3w10ax",
    "fan": "1gfan",
    "gateway": "zcc",
    "mccoy": "mccoy",
    "ugdc": "ugdc",
    "ugds": "ugds"
}

export const appConfig = {
    zimicoreConfig,
    zimiserviceConfig,
    cpActionsConfig,
    deviceTypes
}
